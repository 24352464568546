<template>
    <div>
        <ActualizarEmpresas :tipoBd="'REMU'" />
        <CRow>
            <CCol md="12" lg="12">
                <CCard class="tarjetaGesto">
                    <CCardHeader>
                        <div class="card-header-actions" v-if="isMobile"
                            content="Mostrar opciones de despliegue"
                            v-tippy="{ 
                                placement: 'top',
                                arrow: true, 
                                arrowType: 'round', 
                                animation:'fade',
                                theme: 'google',
                                followCursor : true,
                                showOnInit: true,
                                touchHold: true,
                            }">
                                <CButton class="card-header-action btn-minimize float-left" @click="mostrarOpcionesHeader = !mostrarOpcionesHeader">
                                    <CIcon v-if="mostrarOpcionesHeader" :content="$options.freeSet.cilChevronTop" />
                                    <CIcon v-if="!mostrarOpcionesHeader" :content="$options.freeSet.cilChevronBottom" />
                                </CButton>
                            <!--<CLink class="card-header-action btn-minimize float-left" @click="mostrarOpcionesHeader = !mostrarOpcionesHeader">
                                <CIcon v-if="mostrarOpcionesHeader" :content="$options.freeSet.cilChevronTop" class="card-header-action btn-minimize float-left" @click="mostrarOpcionesHeader = !mostrarOpcionesHeader" />
                                <CIcon v-if="!mostrarOpcionesHeader" :content="$options.freeSet.cilChevronBottom" class="card-header-action btn-minimize float-left" @click="mostrarOpcionesHeader = !mostrarOpcionesHeader" />
                            <</CLink>-->
                            
                        </div>
                        <CRow>
                            <CCol sm="12">
                                <h4 v-if="tipoDocumentos.length==1">Envío de {{tipoDocumentoSelected.nombreDocumento}}</h4>
                                <h4 v-else>Revisión de Documentos</h4>
                            </CCol>
                            <!--<CCol sm="7" class="d-none d-md-block">
                                <CButtonGroup class="float-right mr-3">
                                    <CButton
                                        color="outline-secondary"
                                        class="mx-0"
                                        :pressed="tablaLista"
                                        @click="tablaLista = true">
                                        <CIcon :content="$options.freeSet.cilGrid" />
                                    </CButton>
                                    <CButton
                                        color="outline-secondary"
                                        class="mx-0"
                                        :pressed="!tablaLista"
                                        @click="tablaLista = false">
                                        <CIcon :content="$options.freeSet.cilStorage" />
                                    </CButton>
                                </CButtonGroup>
                            </CCol>-->
                        </CRow>

                    </CCardHeader>
                    <CCardBody>
                        <span v-show="(isMobile)?mostrarOpcionesHeader:true">
                            
                            <CRow><!--switch select ultimo periodo-->
                                <CCol md="12" v-show="(periodos.length > 0)">
                                    Seleccionar último período en proceso
                                    <CSwitch
                                        width="150px"
                                        class="mx-1 align-middle mb-2"
                                        shape="pill"
                                        variant="outline"
                                        :checked="selectPeriodoActual"
                                        @update:checked="seleccionarPeriodoActual"
                                    />
                                </CCol>
                            </CRow>
                            <CRow>
                                <CCol md="2"><!--select tipo Dcto-->
                                    <!--
                                        v-show="tipoDocumentos.length>1"
                                    -->
                                    <multiselect class="m-0 d-inline-block mb-2 multSelectGesto"
                                        v-model="tipoDocumentoSelected"
                                        deselect-label="" 
                                        label="nombreDocumento"
                                        placeholder="Documento"
                                        select-label=""
                                        selectedLabel=""
                                        :options="tipoDocumentos" 
                                        :searchable="true"
                                        :loading="isLoadingTipoDocumentos"
                                        :content="errorDctos==null?'Seleccionar documento':errorDctos"
                                        v-tippy="{ 
                                            placement: 'top',
                                            arrow: true, 
                                            arrowType: 'round', 
                                            animation:'fade',
                                            theme: 'google',
                                            followCursor : true,
                                        }"
                                        @select="seleccionarTipoDocumento">
                                    </multiselect>
                                </CCol>
                                <CCol md="2" v-show="(tipoDocumentoSelected != null) ? (tipoDocumentoSelected.valor =='contratos') : false" >
                                    Mostrar por Periodo 
                                    <CSwitch
                                        v-bind="{labelOn: 'Si',labelOff: 'No'}"
                                        width="150px"
                                        class="mt-1 align-middle mb-2"
                                        shape="pill"
                                        variant="outline"
                                        :checked="mostrarPeriodo"
                                        @update:checked="seleccionarMostraPeriodo"
                                    />
                                </CCol>
                                <CCol md="2" v-show="(tipoDocumentoSelected != null) ? (tipoDocumentoSelected.valor !='contratos') ? true : ((tipoDocumentoSelected != null) ? (tipoDocumentoSelected.valor =='contratos' && mostrarPeriodo == true) : false) : false" ><!--select año-->
                                    <multiselect class="m-0 d-inline-block mb-2 multSelectGesto" 
                                        v-model="periodoSelected"
                                        deselect-label="" 
                                        label="nombrePeriodo" 
                                        placeholder="Periodo" 
                                        select-label=""
                                        selectedLabel=""
                                        :options="periodos" 
                                        :searchable="true"
                                        :loading="isLoadingPeriodos"
                                        :disabled="(tipoDocumentoSelected == null || selectPeriodoActual)?true:false"
                                        @select="seleccionarAnioPeriodo">
                                    </multiselect>
                                </CCol>
                                <CCol md="2" v-show="(tipoDocumentoSelected != null) ? (tipoDocumentoSelected.valor !='contratos') ? true : ((tipoDocumentoSelected != null) ? (tipoDocumentoSelected.valor =='contratos' && mostrarPeriodo == true) : false) : false" ><!--select mes-->
                                    <multiselect class="m-0 d-inline-block mb-2 multSelectGesto" 
                                        v-model="mesSelected"
                                        deselect-label="" 
                                        label="nombreMes" 
                                        placeholder="Mes" 
                                        select-label=""
                                        selectedLabel=""
                                        :options="(periodoSelected == null)?[]:periodoSelected.meses"
                                        :searchable="true"
                                        :loading="isLoadingMes"
                                        :disabled="(periodoSelected == null || selectPeriodoActual)?true:false"
                                        @select="seleccionarMeses">
                                    </multiselect>
                                </CCol>                            
                                <CCol md="3"><!--select filtro empresas-->
                                    <multiselect
                                        class="multSelectGesto mb-2"
                                        :custom-label="customLabelEmp"
                                        v-model="empSelected"
                                        selectedLabel="Seleccionado"
                                        deselectLabel="Quitar"
                                        selectLabel="Filtrar"
                                        placeholder="Filtrar Empresas" 
                                        label="nombreEmpresa" 
                                        track-by="codEmpresa"
                                        :loading="isLoadingEmpresasInfo"
                                        :options="empresasInfo" 
                                        :multiple="true"
                                        @select="filtrarEmpr"
                                        @remove="rmvEmpSel">
                                    </multiselect>
    
                                </CCol>
                                <CCol md="3">
                                    <CButton 
                                        size="sm"
                                        v-show="(tipoDocumentoSelected != null) ? (tipoDocumentoSelected.valor !='contratos') ? (tipoDocumentoSelected == null || periodoSelected == null || mesSelected == null)?false:true : true : false" 
                                        class="btnPrincipalVapp" 
                                        @click="refrescarEmpresas" 
                                        :disabled="isRefrescando">
                                        <CIcon :content="$options.freeSet.cilLoopCircular" 
                                        :class="isRefrescando?'imgr':''" />
                                        Refrescar Información
                                    </CButton>
                                </CCol>
                                
                            </CRow>
                        </span>
                        <!--<TablaEmpresas />-->
                        <CargandoInfo :mostrarCarga="isLoadingEmpresas" :mensajeInfo="'Obteniendo sus Empresas'" />
                        
                        <!--<FormatoListaEmpresa v-if="!isLoadingEmpresas" :empresas="empresasFiltradas"
                        :tipoDocSelected="tipoDocumentoSelected"
                        :mesSelected="mesSelected"
                        :anioSelected="periodoSelected"
                            />-->
                            <!-- v-show="!tablaLista"-->
                        
                        <CRow v-if="!isLoadingEmpresas">
                            <CCol md="12" v-show="tablaLista">
                                <a-table
                                    @expand="expandirEmpresa"
                                    :columns="columns"
                                    :data-source="empresasFiltradas"
                                    :pagination="paginacionEmpresas"
                                    :locale="locale"
                                    class="mt-3"
                                    size="small"
                                    @change="cambioPaginaEmpresas">

                                    <span slot="customTitle">
                                        Empresa
                                    </span>
                                    <span slot="customTitleEnvio">
                                        <span v-show="usaPortalEmpresa && !tipoUserAdmin">Enviar a Revisión</span>
                                        <span v-show="usaPortalEmpresa && tipoUserAdmin">Enviar a Revisión | Aceptar</span>
                                        <span v-show="!usaPortalEmpresa">Aceptar periodo</span>
                                    </span>
                                    
                                    <div slot="nombreEmpresa" slot-scope="empresa">
                                        <a style="text-decoration: none; color:#0b7689" class="ml-1"
                                            content="Ver Info Empresa"
                                            v-tippy="{ 
                                                placement: 'top',
                                                arrow: true, 
                                                arrowType: 'round', 
                                                animation:'fade',
                                                theme: 'google',
                                                followCursor : true,
                                            }"
                                            @click="mostrarInfoEmpresa(empresa)">
                                            <!--<CIcon style="width:15px; height:15px;" :content="$options.freeSet.cilInfo" />-->
                                            <span class="pi pi-info-circle" style="font-size: 1rem; color:#0b7689"></span>
                                
                                        </a>
                                        ({{empresa.codEmpresa}}) {{empresa.nombreEmpresa}} [{{empresa.infoEmpresa.totalTrabajadores}}]<!-- {{ empresa.idEmpresa }}-->
                                    </div>
                                    
                                    <span slot="rutEmpresa" slot-scope="empresa">
                                        {{$utils.formatearRut(empresa.rutEmpresa)}}
                                    </span>

                                    <!--<span slot="notificarPor" slot-scope="empresa">
                                        
                                        <CRow>
                                            <CCol md="6" class="text-left">
                                                <div class="float-left text-right" style="width:50%; text-overflow: ellipsis;">Correo</div>
                                                <div class="float-left" style="width:50%">
                                                    <CSwitch
                                                        width="150px"
                                                        class="mx-1 align-middle"
                                                        size="sm" 
                                                        color="success" 
                                                        shape="pill"
                                                        variant="outline"
                                                        v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                        :checked="empresa.notificar.mail"
                                                        @update:checked="empresa.notificar.mail = !empresa.notificar.mail;"
                                                    />
                                                </div>
                                            </CCol>
                                            <CCol md="6" class="text-left">
                                                <CRow>
                                                    <CCol md="6" class="text-left">Plataforma</CCol>
                                                
                                                    <CCol md="6" class="text-left">
                                                        <CSwitch
                                                            width="150px"
                                                            class="mx-1 align-middle" 
                                                            size="sm"
                                                            color="success" 
                                                            shape="pill"
                                                            variant="outline"
                                                            v-bind="{labelOn: '\u2713',labelOff: '\u2715'}"
                                                            :checked="empresa.notificar.plataforma"
                                                            @update:checked="empresa.notificar.plataforma = !empresa.notificar.plataforma;"
                                                        />
                                                        </CCol>
                                                </CRow>
                                            </CCol>
                                        </CRow>
                                    </span>-->

                                    <span slot="enviar" slot-scope="empresa">
                                        <CButton class="btnPrincipalVapp" v-show="empresa.enviandoEmpresa">
                                            <CIcon :content="$options.freeSet.cilLoopCircular" class="imgr" />
                                            Enviando Empresa
                                        </CButton>
                                        <span v-show="usaPortalEmpresa">
                                            <CButton class="btnPrincipalVapp mr-1"
                                                v-show="!empresa.enviandoEmpresa && empresa.estado!=1 && empresa.infoEmpresa.totalTrabajadores > 0 && !empresa.enviarEmpresa"
                                                :content="(empresa.userNotificar.length == 0)?'(!) OJO - No tiene usuario para revision':'Enviar a Revision'"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }"
                                                @click="enviarEmpresa(empresa)">
                                                <span v-show="empresa.userNotificar.length == 0" 
                                                    style="color:red; font-size:14px;font-weight: bold;">!</span> {{empresa.estado==3?'Volver a Enviar':'Enviar'}}
                                            </CButton>
                                        </span>
                                        <span v-show="tipoUserAdmin || (!tipoUserAdmin && !usaPortalEmpresa)">
                                            <CButton class="btnPrincipalVapp"
                                                v-show="!empresa.enviandoEmpresa && (empresa.estado!=1 && empresa.estado!=3) && empresa.infoEmpresa.totalTrabajadores > 0 && !empresa.enviarEmpresa"
                                                @click="empresa.enviarEmpresa = true">
                                                Aceptar
                                            </CButton>

                                            <CButtonGroup size="sm" class="mx-1" v-show="empresa.enviarEmpresa">
                                                
                                                <CButton 
                                                    color="success" 
                                                    size="sm"
                                                    @click="aceptarEmpresa(empresa); empresa.enviarEmpresa = false"
                                                    content="Aceptar Empresa"
                                                    v-tippy="{ 
                                                        placement: 'top',
                                                        arrow: true, 
                                                        arrowType: 'round', 
                                                        animation:'fade',
                                                        theme: 'google',
                                                        followCursor : true,
                                                    }">
                                                    <CIcon name="cil-check-circle"/>
                                                </CButton>

                                                <CButton
                                                    content="Cancelar"
                                                    v-tippy="{ 
                                                        placement: 'top',
                                                        arrow: true, 
                                                        arrowType: 'round', 
                                                        animation:'fade',
                                                        theme: 'google',
                                                        followCursor : true,
                                                    }"
                                                    color="danger" 
                                                    @click="empresa.enviarEmpresa = false" size="sm" ><CIcon name="cil-x-circle"/>
                                                </CButton>
                                            </CButtonGroup>
                                            <span v-show="empresa.infoEmpresa.totalTrabajadores == 0">
                                                <CButton
                                                   size="sm" variant="ghost"
                                                    content="No hay informacion para ser Aceptada"
                                                        v-tippy="{ 
                                                            placement: 'top',
                                                            arrow: true, 
                                                            arrowType: 'round', 
                                                            animation:'fade',
                                                            theme: 'google',
                                                            followCursor : true,
                                                        }"
                                                    >
                                                    <CIcon
                                                        :content="$options.freeSet.cilWarning"
                                                    />
                                                </CButton>
                                               
                                            </span>
                                            <!--<span v-show="!empresa.enviandoEmpresa && empresa.estado==3">Periodo Aceptado</span>-->
                                        </span>
                                        <span v-show="!empresa.enviandoEmpresa && empresa.estado==1">
                                            <CButton
                                                :disabled="empresa.cancelandoEnvio"
                                                class="btnPrincipalVapp"
                                                size="sm"
                                                content="Cancelar revision Empresa"
                                                v-tippy="{ 
                                                    placement: 'top',
                                                    arrow: true, 
                                                    arrowType: 'round', 
                                                    animation:'fade',
                                                    theme: 'google',
                                                    followCursor : true,
                                                }"
                                                @click="cancelarRevision(empresa)"
                                            >
                                                <CIcon :class="empresa.cancelandoEnvio?'imgr':''" :content="$options.freeSet.cilXCircle" /> 
                                                Cancelar Revisión
                                            </CButton>
                                        </span>
                                    </span>

                                    <span slot="estado" slot-scope="empresa">
                                        <CBadge
                                            class="m-1"
                                            style="font-size:0.7rem; font-weight: normal;"
                                            :content="(empresa.estado==1)?'En Revisón':(empresa.estado==2)?'Rechazado '+ empresa.mensajeRespuesta+' ('+$utils.formatFechaEnDistancia(empresa.fechaMensaje)+')':(empresa.estado==3)?'Aceptado':'Sin Enviar'"
                                            v-tippy="{ 
                                                placement: 'top',
                                                arrow: true, 
                                                arrowType: 'round', 
                                                animation:'fade',
                                                theme: 'google' 
                                            }"
                                            shape="pill" :color="(empresa.estado==1)?'info':(empresa.estado==2)?'danger':(empresa.estado==3)?'success':'secondary'">
                                            
                                            <CIcon v-if="empresa.estado==1" style="width:20px; height:20px;" :content="$options.freeSet.cilArrowCircleRight" />
                                            <CIcon v-else-if="empresa.estado==2" style="width:20px; height:20px;" :content="$options.freeSet.cilXCircle" />
                                            <CIcon v-else-if="empresa.estado==3" style="width:20px; height:20px;" :content="$options.freeSet.cilCheckCircle" />
                                            <CIcon v-else style="width:20px; height:20px;" :content="$options.freeSet.cilBan" />
                                        </CBadge>
                                        <CBadge
                                            v-show="empresa.periodoCerrado"
                                            class="m-1"
                                            :content="`Periodo Cerrado ${empresa.fechaPeriodoCerrado==undefined || empresa.fechaPeriodoCerrado== ''?'por aplicación Edig':$utils.formatFechaEnDistancia(empresa.fechaPeriodoCerrado)}`"
                                            v-tippy="{ 
                                                placement: 'top',
                                                arrow: true, 
                                                arrowType: 'round', 
                                                animation:'fade',
                                                theme: 'google' 
                                            }"
                                            
                                            shape="pill" 
                                            style="font-size:0.7rem; font-weight: normal; background-color:#29235C; color:white">
                                            <CIcon style="width:20px; height:20px;" :content="$options.freeSet.cilLockLocked" />
                                        </CBadge>
                                        
                                        <CButton
                                            v-show="tipoDocumentoSelected.valor !='contratos' && !empresa.periodoCerrado && empresa.estado==3"
                                            shape="pill"
                                            content="Cerrar Periodo" 
                                            v-tippy="{ 
                                                placement: 'top',
                                                arrow: true, 
                                                arrowType: 'round', 
                                                animation:'fade',
                                                theme: 'google',
                                            }"
                                            style="font-size:11px;padding:1px"
                                            class="btnPrincipalVapp"
                                            @click="cerrarPeriodo(empresa)">
                                            <CIcon style="width:20px; height:20px;" :content="$options.freeSet.cilDoor" />
                                        </CButton>
                                        <CButton
                                            v-show="empresa.periodoCerrado && empresa.estado==3"
                                            shape="pill"
                                            content="Abrir Periodo" 
                                            v-tippy='{ placement : "left" }'
                                            style="font-size:11px;padding:1px"
                                            class="btnPrincipalVapp"
                                            @click="abrirPeriodo(empresa)">
                                            <CIcon style="width:20px; height:20px;" :content="$options.freeSet.cilRoom" />
                                        </CButton>
                                    </span>

                                    <div slot="expandedRowRender" slot-scope="empresa" style="width:99%; margin: 0">
                                        
                                        <CRow>
                                            <CCol md="4">
                                                <FiltroTrabajadores ref="filtroTrab" 
                                                    :empresaSelected="empresa"
                                                    :tipoDocSelected="tipoDocumentoSelected"
                                                    :mesSelected="mesSelected"
                                                    :anioSelected="periodoSelected"
                                                    @seleccionarTrab="filtrarTrabajadores($event, empresa)"
                                                />
                                            </CCol>
                                            <CCol md="3">
                                                <OpcionesFiltrosTrabajadores 
                                                    :opcionesFiltrosTrabajadores="empresa.opcionesFiltrosTrabajadores"
                                                    @seleccionarFiltro="seleccionFiltro($event, empresa)"
                                                />
                                            </CCol>
                                            <CCol md="4">
                                                <CDropdown 
                                                    color="drpdwn-menu"
                                                    class="mt-1"
                                                    size="sm"
                                                    v-show="empresa.trabajadores.length>0">
                                                    <template #toggler-content>
                                                        <!--:disabled="empresa.isActualizandoDocumentos || empresa.isLoadingEnvioTodos || empresa.isLoadingGenerarDocumento || empresa.isDescargando"-->
                                                        <CIcon :class="(empresa.isActualizandoDocumentos || empresa.isLoadingEnvioTodos || empresa.isLoadingGenerarDocumento || empresa.isDescargando)?'imgr':''" :content=" $options.freeSet.cilApplications" />
                                                        <!--<CIcon :content="$options.freeSet.cilOptions" />-->
                                                    </template>
                                                    <div role="group">
                                                        
                                                        <CDropdownHeader v-show="empresa.periodoCerrado" class="font-weight-bold"
                                                            content="Opciones deshabilitadas por que el periodo se encuentra cerrado"
                                                            v-tippy="{ 
                                                                placement: 'top',
                                                                arrow: true, 
                                                                arrowType: 'round', 
                                                                animation:'fade',
                                                                theme: 'google',
                                                                followCursor : true,
                                                            }"
                                                        >
                                                            
                                                            <CIcon
                                                                style="color:#f9b115"
                                                                :content="$options.freeSet.cilWarning"
                                                            /> Hay Opciones deshabilitadas
                                                        </CDropdownHeader><!--icono Periodo Cerrado--> 

                                                        <CDropdownItem 
                                                            :disabled="empresa.isActualizandoDocumentos || empresa.isLoadingEnvioTodos || empresa.isLoadingGenerarDocumento || empresa.isDescargando || empresa.estado==1 || empresa.estado==3" 
                                                            v-show="tipoDocumentoSelected.permiteSubirDocs" 
                                                            @click="mostrarSubirArchivos(empresa)">
                                                            <CIcon class="mr-1" 
                                                                :content="empresa.periodoCerrado?$options.freeSet.cilWarning : $options.freeSet.cilCloudUpload" /> 
                                                            Subir Documentos 
                                                        </CDropdownItem>
                                                        
                                                        <CDropdownItem
                                                            :content="(empresa.mostrarMensajeActualizacionDocumentos)?empresa.mensajeActualizacionDocs:'Actualizar Documentos'"                                            
                                                            v-tippy="{ 
                                                                placement: 'top',
                                                                arrow: true, 
                                                                arrowType: 'round', 
                                                                animation:'fade',
                                                                theme: 'google',
                                                                followCursor : true,
                                                            }"
                                                            :disabled="empresa.isActualizandoDocumentos || empresa.isLoadingEnvioTodos || empresa.isLoadingGenerarDocumento || empresa.isDescargando || empresa.estado==3"
                                                            v-show="tipoDocumentoSelected.valor == 'liquidaciones'"
                                                            @click="actualizarDocumentosEmpresa(empresa)">
                                                            <CIcon class="mr-1" :class="(empresa.isActualizandoDocumentos)?'imgr':''" 
                                                                :content="empresa.periodoCerrado?$options.freeSet.cilWarning : $options.freeSet.cilLoopCircular" /> 
                                                            {{empresa.isActualizandoDocumentos?"Actualizando ": "Actualizar "}} Documentos
                                                            
                                                            <CIcon 
                                                                class="ml-1"
                                                                v-show="empresa.mostrarMensajeActualizacionDocumentos"
                                                                :content="$options.freeSet.cilInfo"
                                                                
                                                            />
                                                        </CDropdownItem>

                                                        <!--<CDropdownItem 
                                                            v-show="tipoDocumentoSelected.permiteGenerarDoc" 
                                                            :disabled="empresa.isActualizandoDocumentos || empresa.isLoadingEnvioTodos || empresa.isLoadingGenerarDocumento || empresa.isDescargando"
                                                            @click="generarDocumentos(empresa)" >
                                                            <CIcon class="mr-1" 
                                                                :class="(empresa.isLoadingGenerarDocumento)?'imgr':''" 
                                                                :content="$options.freeSet.cilLibraryAdd" /> Generar Documentos
                                                        </CDropdownItem>-->
                                                        
                                                        <CDropdownItem 
                                                            :content="empresa.txtEnviarTodos"
                                                            v-tippy="{ 
                                                                placement: 'top',
                                                                arrow: true, 
                                                                arrowType: 'round', 
                                                                animation:'fade',
                                                                theme: 'google',
                                                                followCursor : true,
                                                            }"
                                                            v-show="empresa.hayDocumentosTrabajador" 
                                                            :disabled="empresa.isActualizandoDocumentos || empresa.isLoadingEnvioTodos || empresa.isLoadingGenerarDocumento || empresa.isDescargando" 
                                                            @click="enviaraTodos(empresa)">
                                                            <CIcon 
                                                            class="mr-1" 
                                                            :class="(empresa.isLoadingEnvioTodos)?'imgr':''" 
                                                            :content="$options.freeSet.cilEnvelopeLetter" /> Enviar Documentos
                                                        </CDropdownItem>
                                                    </div>
                                                    <div role="group" v-show="empresa.hayDocumentosTrabajador">
                                                        <CDropdownDivider />
                                                        <CDropdownHeader class="font-weight-bold">
                                                            <CIcon class="mr-1" 
                                                                :class="(empresa.isDescargando)?'imgr':''" 
                                                                :content="$options.freeSet.cilCloudDownload" /> Descarga de Documentos
                                                        </CDropdownHeader>
                                                        <CDropdownItem
                                                            :disabled="empresa.isActualizandoDocumentos || empresa.isLoadingEnvioTodos || empresa.isLoadingGenerarDocumento || empresa.isDescargando"
                                                            content="Descargar un Archivo con todos los documentos"
                                                            v-tippy="{ 
                                                                placement: 'top',
                                                                arrow: true, 
                                                                arrowType: 'round', 
                                                                animation:'fade',
                                                                theme: 'google',
                                                                followCursor : true,
                                                            }"
                                                            @click="descargarArchivo('merge',empresa)">
                                                            <CIcon class="mr-1" 
                                                                :class="(empresa.isDescargando)?'imgr':''" 
                                                                :content="$options.freeSet.cilSwapVertical" /> Documentos Unificados
                                                        </CDropdownItem>
                                                        <CDropdownItem
                                                            content="Descargar todos los documentos comprimidos" 
                                                            v-tippy="{ 
                                                                placement: 'top',
                                                                arrow: true, 
                                                                arrowType: 'round', 
                                                                animation:'fade',
                                                                theme: 'google' 
                                                            }"
                                                            :disabled="empresa.isActualizandoDocumentos || empresa.isLoadingEnvioTodos || empresa.isLoadingGenerarDocumento || empresa.isDescargando"
                                                            @click="descargarArchivo('zip',empresa)">
                                                            <CIcon class="mr-1" 
                                                                :class="(empresa.isDescargando)?'imgr':''" 
                                                                :content="$options.freeSet.cilCompress" /> Documentos comprimidos
                                                        </CDropdownItem>
                                                    </div>
                                                </CDropdown>
                                                
                                            </CCol>
                                        </CRow>
                                        <CRow v-show="empresa.isLoadingTrabajadores">
                                            <CCol md="12">
                                                <center>
                                                    <img src="/img/logo2.png" size="custom-size" :height="50" class="imgr mt-3 mb-3" /> Obteniendo Trabajadores...
                                                </center>
                                            </CCol>
                                        </CRow>
                                        
                                        <CRow v-show="empresa.mostrarSubirDocumentos">
                                            <CCol>
                                                <hr>
                                                <div class="upload" v-show="!empresa.isOption">
                                                    <!--<button
                                                        type="button"
                                                        class="btn btn-danger float-right btn-is-option mb-2"
                                                        @click.prevent="empresa.isOption = !empresa.isOption">
                                                        <i class="fa fa-cog" aria-hidden="true"></i> Opciones
                                                    </button>-->
                                                        <file-upload
                                                            class="btn btnPrincipalVapp mr-2"
                                                            :post-action="empresa.linkSubir"
                                                            :headers="headers"
                                                            :extensions="extensions"
                                                            :accept="accept"
                                                            :multiple="multiple"
                                                            :directory="directory"
                                                            :size="size || 0"
                                                            :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
                                                            :drop="drop"
                                                            :drop-directory="dropDirectory"
                                                            :add-index="addIndex"
                                                            v-model="empresa.archivosSubir"
                                                            @input-file="inputFile"
                                                            ref="upload">
                                                            <CIcon :content="$options.freeSet.cilPlus" />
                                                            Agregar Archivos
                                                        </file-upload>

                                                        <button
                                                            style="font-size: 11px;"
                                                            v-show="empresa.archivosSubir.length>0 && !empresa.isLoadingSubir"
                                                            type="button"
                                                            class="btn btn-danger mr-2"
                                                            @click.prevent="empresa.archivosSubir = []">
                                                            <CIcon :content="$options.freeSet.cilBan"/>
                                                            Quitar Documentos
                                                        </button>

                                                        <button
                                                            style="font-size: 11px;"
                                                            type="button"
                                                            class="btn btnPrincipalVapp mr-2"
                                                            v-if="!$refs.upload || !$refs.upload.active"
                                                            @click.prevent="subirTodoActivar(empresa);">
                                                            <CIcon :content="$options.freeSet.cilCloudUpload"/>
                                                            Subir todo
                                                        </button>

                                                        <button
                                                            style="font-size: 11px;"
                                                            type="button"
                                                            class="btn btn-danger mr-2"
                                                            v-else
                                                            @click.prevent="$refs.upload.active = false">
                                                            <CIcon :content="$options.freeSet.cilXCircle"/>
                                                            Detener todo
                                                        </button>

                                                        <button
                                                            style="font-size: 11px;"
                                                            type="button"
                                                            class="btn btnPrincipalVapp"
                                                            @click.prevent="empresa.mostrarSubirDocumentos = !empresa.mostrarSubirDocumentos">
                                                            <CIcon :content="$options.freeSet.cilBan"/>
                                                            Cerrar subir Documentos
                                                        </button>
                                                    <!--<div>
                                                        asd: {{getPorcentajeSubidos(empresa.archivosSubir)}}
                                                        {{getColorPorcentaje(empresa.archivosSubir)}}
                                                        <hr>
                                                        <span>progreso:</span> 
                                                        <span>
                                                            <CProgress 
                                                                :value="getDocsProcesados(empresa.archivosSubir)"
                                                                :max="empresa.archivosSubir.length"
                                                                :precision="2" 
                                                                :animated="true"
                                                                show-value
                                                            />
                                                        </span>
                                                    </div>-->
                                                    <div class="table-responsive">
                                                        <a-table
                                                            row-key="name"
                                                            :columns="columnasSubidaArchivos" 
                                                            :data-source="empresa.archivosSubir"
                                                            :scroll="{ y: 400 }"
                                                            class="tableInterior mt-3" 
                                                            size="small">
                                                            <span slot="nombreArchivo" slot-scope="archivo">
                                                                <CRow>
                                                                    <CCol>{{archivo.name}}</CCol>
                                                                </CRow>
                                                            </span>
                                                            <template slot="progreso" slot-scope="archivo">
                                                                <CRow>
                                                                    <CCol>
                                                                        <CProgress 
                                                                            :value="parseFloat(archivo.progress)"
                                                                            :precision="2" 
                                                                            show-percentage 
                                                                            :animated="true"
                                                                            :color="(archivo.error)?'danger':'success'"
                                                                        />
                                                                    </CCol>
                                                                </CRow>
                                                            </template>
                                                            <template slot="tamanio" slot-scope="archivo">
                                                                {{archivo.size | prettyBytes}}
                                                            </template>

                                                            <template slot="velocidad" slot-scope="archivo">
                                                                {{ archivo.speed | prettyBytes }}/s
                                                            </template>

                                                            <template slot="estado" slot-scope="archivo">
                                                                <span v-if="archivo.error" style="color:#7E1146">
                                                                    <span v-if="archivo.error === 'size'">
                                                                        Archivo demasiado pequeño
                                                                    </span>
                                                                    <span v-else>{{ archivo.response.mensaje }}</span>
                                                                </span>
                                                                <!--<CBadge v-if="archivo.error" color="danger">
                                                                    <span v-if="archivo.error === 'size'">
                                                                        Archivo demasiado pequeño
                                                                    </span>
                                                                    <span v-else>{{ archivo.response }}</span>
                                                                </CBadge>-->
                                                                <span v-else-if="archivo.success" style="color: #2eb85c">
                                                                    {{archivo.response.mensaje}}
                                                                </span>
                                                                <CBadge v-else-if="archivo.active" color="warning" >
                                                                    Subiendo
                                                                </CBadge>
                                                                <CBadge v-else color="secondary" >
                                                                    En espera
                                                                </CBadge>
                                                            </template>

                                                            <template slot="acciones" slot-scope="archivo">
                                                                <CButtonGroup class="mx-1">
                                                                    <CButton
                                                                        v-if="archivo.active" 
                                                                        title="Cancelar subida archivo"
                                                                        color="danger" 
                                                                        size="sm"
                                                                        @click.prevent="archivo.active? $refs.upload.update(archivo, { error: 'Cancelado' }): false">
                                                                        <CIcon name="cil-x-circle"/>
                                                                    </CButton>

                                                                    <CButton
                                                                        v-else-if="archivo.error && archivo.error !== 'compressing' && $refs.upload.features.html5"
                                                                        title="Recargar archivo"
                                                                        color="info" 
                                                                        size="sm"
                                                                        @click.prevent="$refs.upload.update(archivo, {active: true,error: '',progress: '0.00'})">
                                                                        <CIcon :content="$options.freeSet.cilLoopCircular"/>
                                                                    </CButton>

                                                                    <CButton
                                                                        v-else
                                                                        title="Subir archivo"
                                                                        color="success" 
                                                                        size="sm"
                                                                        @click.prevent="archivo.success || archivo.error === 'compressing'? false: $refs.upload.update(archivo, {active: true,error: '',progress: '0.00'})">
                                                                        <CIcon :content="$options.freeSet.cilCloudUpload"/>
                                                                    </CButton>

                                                                    <CButton
                                                                        title="Quitar archivo"
                                                                        color="danger" 
                                                                        size="sm"
                                                                        v-show="!archivo.active"
                                                                        @click.prevent="archivo.active? false : $refs.upload.remove(archivo)">
                                                                        <CIcon :content="$options.freeSet.cilTrash"/>
                                                                    </CButton>
                                                                    
                                                                </CButtonGroup>
                                                            </template>
                                                            
                                                            
                                                        </a-table>
                                                        <div v-if="!(empresa.archivosSubir.length>0)" class="text-center p-5">
                                                        <h4>Arrastra aquí una carpeta o un archivo</h4>
                                                        </div>
                                                    </div>
                                                </div>

                                                <!--<div class="option" v-show="empresa.isOption">
                                                    <div class="form-group">
                                                        <label for="thread">Thread:</label>
                                                        <input
                                                            type="number"
                                                            max="5"
                                                            min="1"
                                                            id="thread"
                                                            class="form-control"
                                                            v-model.number="thread"
                                                        />
                                                        <small class="form-text text-muted"
                                                        >Also upload the number of files at the same time (number of
                                                        threads)</small
                                                        >
                                                    </div>

                                                    <div class="form-group">
                                                        <label for="autoCompress">Automatically compress:</label>
                                                        <input
                                                        type="number"
                                                        min="0"
                                                        id="autoCompress"
                                                        class="form-control"
                                                        v-model.number="autoCompress"
                                                        />
                                                        <small class="form-text text-muted" v-if="autoCompress > 0"
                                                        >More than {{ autoCompress | prettyBytes }} files are automatically
                                                        compressed</small
                                                        >
                                                        <small class="form-text text-muted" v-else
                                                        >Set up automatic compression</small
                                                        >
                                                    </div>

                                                    <div class="form-group">
                                                        <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input
                                                            type="checkbox"
                                                            id="add-index"
                                                            class="form-check-input"
                                                            v-model="addIndex"
                                                            />
                                                            Start position to add
                                                        </label>
                                                        </div>
                                                        <small class="form-text text-muted"
                                                        >Add a file list to start the location to add</small
                                                        >
                                                    </div>

                                                    <div class="form-group">
                                                        <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input
                                                            type="checkbox"
                                                            id="drop"
                                                            class="form-check-input"
                                                            v-model="drop"
                                                            />
                                                            Drop
                                                        </label>
                                                        </div>
                                                        <small class="form-text text-muted">Drag and drop upload</small>
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input
                                                            type="checkbox"
                                                            id="drop-directory"
                                                            class="form-check-input"
                                                            v-model="dropDirectory"
                                                            />
                                                            Drop directory
                                                        </label>
                                                        </div>
                                                        <small class="form-text text-muted"
                                                        >Not checked, filter the dragged folder</small
                                                        >
                                                    </div>
                                                    <div class="form-group">
                                                        <div class="form-check">
                                                        <label class="form-check-label">
                                                            <input
                                                            type="checkbox"
                                                            id="upload-auto"
                                                            class="form-check-input"
                                                            v-model="uploadAuto"
                                                            />
                                                            Auto start
                                                        </label>
                                                        </div>
                                                        <small class="form-text text-muted"
                                                        >Automatically activate upload</small
                                                        >
                                                    </div>
                                                    <div class="form-group">
                                                        <button
                                                        type="button"
                                                        class="btn btn-primary btn-lg btn-block"
                                                        @click.prevent="empresa.isOption = !empresa.isOption"
                                                        >
                                                        Confirm
                                                        </button>
                                                    </div>
                                                </div
                                                <div
                                                    :class="{ modal: true, fade: true, show: addData.show }"
                                                    id="modal-add-data"
                                                    tabindex="-1"
                                                    role="dialog"
                                                >
                                                    <div class="modal-dialog" role="document">
                                                        <div class="modal-content">
                                                        <div class="modal-header">
                                                            <h5 class="modal-title">Add data</h5>
                                                            <button
                                                            type="button"
                                                            class="close"
                                                            @click.prevent="addData.show = false"
                                                            >
                                                            <span>&times;</span>
                                                            </button>
                                                        </div>
                                                        <form @submit.prevent="onAddData">
                                                            <div class="modal-body">
                                                            <div class="form-group">
                                                                <label for="name">Name:</label>
                                                                <input
                                                                type="text"
                                                                class="form-control"
                                                                required
                                                                id="name"
                                                                placeholder="Please enter a file name"
                                                                v-model="addData.name"
                                                                />
                                                                <small class="form-text text-muted"
                                                                >Such as <code>filename.txt</code></small
                                                                >
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="type">Type:</label>
                                                                <input
                                                                type="text"
                                                                class="form-control"
                                                                required
                                                                id="type"
                                                                placeholder="Please enter the MIME type"
                                                                v-model="addData.type"
                                                                />
                                                                <small class="form-text text-muted"
                                                                >Such as <code>text/plain</code></small
                                                                >
                                                            </div>
                                                            <div class="form-group">
                                                                <label for="content">Content:</label>
                                                                <textarea
                                                                class="form-control"
                                                                required
                                                                id="content"
                                                                rows="3"
                                                                placeholder="Please enter the file contents"
                                                                v-model="addData.content"
                                                                ></textarea>
                                                            </div>
                                                            </div>
                                                            <div class="modal-footer">
                                                            <CButton
                                                                class="btnPrincipalVapp"
                                                                size="sm"
                                                                @click.prevent="addData.show = false"
                                                                >Close
                                                            </CButton>
                                                            <CButton class="btnPrincipalVapp" size="sm">Save </CButton>
                                                            </div>
                                                        </form>
                                                        </div>
                                                    </div>
                                                </div>>-->
                                                <hr>
                                            </CCol>
                                        </CRow>
                                        <CRow v-show="!empresa.isLoadingTrabajadores">
                                            <CCol md="12">
                                                <TblTrabDocumentos
                                                    :portContador="true"
                                                    :empresa="empresa"
                                                    :periodoCerrado="empresa.periodoCerrado"
                                                    :periodoSelected="periodoSelected"
                                                    :mesSelected="mesSelected"
                                                    :trabajadores="empresa.trabajadores"
                                                    :loading="false"
                                                    :paginacion="empresa.paginacion"
                                                    :tipoDocumentoSelected="tipoDocumentoSelected"
                                                    :totalTrabajadoresDocumentos="empresa.totalTrabajadoresDocumentos"
                                                    :totalTrabajadores="empresa.totalTrabajadores"
                                                    :mostrarPeriodo="mostrarPeriodo"
                                                    :revision="this"
                                                />
                                            </CCol>
                                        </CRow>
                                   </div>
                                </a-table>
                            </CCol>
                        </CRow>
                    </CCardBody>
                    <CCardFooter>
                    </CCardFooter>
                </CCard>
            </CCol>
        </CRow>
        
        <Modal :enableClose="false" v-model="mostrarModalEnvioEmpresa">
            <div class="vm-titlebar">
                <h3 class="vm-title">
                    Envío de empresa ({{empresaEnviar.codEmpresa}}) {{empresaEnviar.nombreEmpresa}}
                </h3>
            </div>
            <div class="vm-content">
                <slot v-if="!mailEmpresaOk">
                    <div v-if="empresaEnviar.userNotificar.length == 0">
                        <CRow>
                            <CCol md="12">
                                <CAlert v-show="true" color="warning">
                                    Esta empresa no tiene un usuario para su revisión, le recomendamos configurar un usuario revisión y asignarle a esta empresa
                                </CAlert>
                            </CCol>
                        </CRow>
                    </div>
                    <div v-else-if="!mailEmpresaOk">
                        <CRow>
                            <CCol md="12">
                                <CAlert v-show="true" color="warning">
                                    {{empresaEnviar.userNotificar.length>1?'Uno o más correos':'El correo del encargado'}} no se encuentra registrado o es invalido, ingrese a quién se debe enviar la notificación
                                </CAlert>
                            </CCol>
                        </CRow>
                        <CRow>
                            <CCol md="12">
                                <CAlert v-show="true" color="warning">
                                    Se notificará a los siguientes usuarios asignados para su revision
                                </CAlert>
                            </CCol>
                        </CRow>
                        <CRow v-for="user in empresaEnviar.userNotificar" :key="user.idUser">
                            <CCol md="9">
                                <CInput
                                    horizontal
                                    :label="user.username"
                                    v-model="user.mailUser"
                                    placeholder="ingresar email encargado"
                                    :is-valid="user.mailValidado"
                                />
                            </CCol>
                            <CCol md="3">
                                <CButtonGroup class="mx-1">
                                    <CButton color="success" size="sm" @click="isEmailValido">
                                        <CIcon name="cil-check-circle"/>
                                    </CButton>
                                </CButtonGroup>
                            </CCol>
                        </CRow>
                    </div>
                </slot>
                <slot v-else>
                    <CRow>
                        <CCol md="12">
                            <CAlert v-show="true" color="warning">
                                Se enviará a revision {{tipoDocumentoSelected.nombreDocumento}} de la empresa
                            </CAlert>
                        </CCol>
                    </CRow>
                    
                    <CRow v-for="user in empresaEnviar.userNotificar" :key="user.idUser">
                        
                        <CCol md="9">
                            <CInput
                                horizontal
                                :label="user.username"
                                v-model="user.mailUser"
                                type="email"
                                :disabled="!user.editCorreoEncargado"
                                placeholder="ingresar email encargado"
                            />
                        </CCol>
                        <CCol md="3" class="align-middle" style="vertical-align:middle">
                            <CButtonGroup class="mx-1">
                                <CButton color="success" size="sm" 
                                    @click="editarCorreoEmpresa(user)" v-show="!user.editCorreoEncargado">
                                    <CIcon name="cil-pencil"/>
                                </CButton>
                            </CButtonGroup>
                            
                            <CButtonGroup class="mx-1" v-show="user.editCorreoEncargado">
                                <CButton color="success" size="sm" @click="isEmailValido">
                                    <CIcon name="cil-check-circle"/>
                                </CButton>
                            
                                <CButton color="danger" size="sm" @click="cancelarEditarEmpresa(user)">
                                    <CIcon name="cil-x-circle"/>
                                </CButton>
                            </CButtonGroup>
                        </CCol>
                        
                    </CRow>
                    
                </slot>
                
            </div>
            <div class="vm-footer">
                <CRow v-show="isLoadingEnviandoEmpresa">
                    <CCol md="12">
                        <CIcon :content="$options.freeSet.cilLoopCircular" class="imgr" /> {{msjEnviandoRevision}}
                    </CCol>
                </CRow>
                <CRow v-show="!isLoadingEnviandoEmpresa">
                    <CCol md="6" v-show="!editCorreoEncargado && mailEmpresaOk && !empresaEnviada">
                        <CButton type="submit" size="sm" color="success" class="float-left" v-show="!editCorreoEncargado && mailEmpresaOk && !empresaEnviada" @click="enviarRevision">
                            <CIcon name="cil-check-circle"/> Enviar a Revisión
                        </CButton>
                    </CCol>
                    <CCol md=6 v-show="mensajeCorreoInvalido">
                        <CAlert v-show="mensajeCorreoInvalido" color="danger">
                            Coreo Invalido
                        </CAlert>
                    </CCol>
                    <CCol md="6">
                        <CButton type="submit" size="sm" color="danger" class="float-right" 
                            @click="cancelarEnvio();mostrarModalEnvioEmpresa = false">
                            <CIcon name="cil-x-circle"/> Salir
                        </CButton>
                    </CCol>
                </CRow> 
            </div>
        </Modal>
        <ModalInfoEmpresa 
            :mostrarModal="infoEmpresaModal"
            :empresa="empresaSeleccionadaInfo"
            :webPadre="this"
        />

    </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>


<script>
    
    window.locale = 'es'
    /* Libs */
    import cons from '@/const'
    import axios from "axios"
    import apiUrls from '@/views/utils/apiUrls'
    import * as util from '@/views/utils/utilsFunctions'
    /* Fin Libs */

    /* Componentes Externos */
    import Multiselect from 'vue-multiselect'

    import VueModal from '@kouts/vue-modal';
    import '@kouts/vue-modal/dist/vue-modal.css';
    
    import { freeSet } from '@coreui/icons'

    import FileUpload from "vue-upload-component";

    /* Fin Componentes Externos */

    /* Componentes Propios */
    import ActualizarEmpresas from "@/views/utils/ActualizarEmpresas";
    import CargandoInfo from '@/views/utils/CargandoInfo'

    import TablaEmpresas from "./componentes/TablaEmpresas";
    import FiltroTrabajadores from "./componentes/FiltroTrabajadoresComponente";
    import OpcionesFiltrosTrabajadores from "./componentes/filtros/OpcionesFiltrosTrabajadores";
    import TblTrabDocumentos from "./componentes/TablaTrabajadoresDocumentos";

    import ModalPdfViewDocs from "./componentes/ModalPdfViewDocs";
    import ModalInfoEmpresa from "./componentes/ModalInfoEmpresa";
    
    // en Test
    import FormatoListaEmpresa from "./componentes/FormatoListaEmpresas";
    //import ModalSubirDoc from "./componentes/ModalSubirDocumentos";
    /* Fin Componentes Propios */
    

    export default {
        name: 'RevisionDocumentos',
        freeSet,
        components: {
            Multiselect,
            'Modal': VueModal,
            ModalPdfViewDocs,
            ModalInfoEmpresa,
            FileUpload,
            TablaEmpresas,
            ActualizarEmpresas,
            TblTrabDocumentos,
            CargandoInfo,
            FiltroTrabajadores,
            OpcionesFiltrosTrabajadores,
            FormatoListaEmpresa
        },
        data () {
            return {
                tokenLogin: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                tipoUserAdmin: JSON.parse(this.$cookie.get('userLogginToken')).tipoUsuario,
                idCliente: JSON.parse(this.$cookie.get('userLogginToken')).idCliente,
                usaPortalEmpresa: JSON.parse(this.$cookie.get('userLogginToken')).usaPortalEmpresa,
                
                urlBase: cons.port+"://" + cons.ipServer,
                
                isMobile: false,
                mostrarOpcionesHeader: false,
                tablaLista: true,
                
                rqstActualizarEmpresas: null,

                /** Selecciones Iniciales */
                isLoadingPeriodos: false,
                isLoadingMes: false,
                isLoadingTipoDocumentos: false,
                isLoadingEmpresasInfo: false,

                periodos: [],
                tipoDocumentos: [],

                selectPeriodoActual: false,
                periodoSelected: null,
                mesSelected: null,
                tipoDocumentoSelected: null,
                errorDctos: null,
                mostrarPeriodo: false,
                
                empSelected: [],
                empresasInfo: [],
                /** Fin Selecciones Iniciales */

                /** Selecciones Empresas */
                isRefrescando: false,
                isLoadingEmpresas: false,
                
                mailEmpresaOk: false,
                mailValido: false,

                empresaEnviar: {userNotificar: {}},
                empresas: [],
                empresasFiltradas: [],
                emFlt: [],
                rqstEmps: null,
                paginacionEmpresas: { pageSize: 30, hideOnSinglePage: true },

                columns: [
                    { slots: { title: 'customTitle' }, scopedSlots: { customRender: 'nombreEmpresa' }, width: '35%', ellipsis: true, onFilter: (value, record)=>record.idEmpresa == value},
                    { title: 'Rut Empresa', scopedSlots:{ customRender: 'rutEmpresa' }, width: '20%', align: 'center', ellipsis: true, onFilter: (value, record)=>record.rutEmpresa == value},
                    { slots: { title: 'customTitleEnvio' }, scopedSlots: { customRender: 'enviar' }, width: '20%', align: 'center', ellipsis: true},
                    { title: 'Estado', scopedSlots: { customRender: 'estado' }, width: '25%', ellipsis: false},
                ],

                locale: {
                    filterConfirm: 'Filtrar',
                    filterReset: 'Limpiar',
                    emptyText: 'Seleccione un periodo para mostrar información'
                },
                /** Fin Selecciones Empresa */

                /** Modal Envio Empresa */
                isLoadingEnviandoEmpresa: false,

                mensajeCorreoInvalido: false,
                mostrarModalEnvioEmpresa: false,
                editCorreoEncargado: false,
                empresaEnviada: false,
                msjEnviandoRevision: "Enviando...",
                respEmpresaEnviar: {},
                /** Fin Modal Envio Empresa */

                /** Modal Info Empresa */
                empresaSeleccionadaInfo: {},
                infoEmpresaModal: false,
                modificarCorreoEmpresa: false,
                /** Fin Modal Info Empresa */


                /**ELIMINAR */
                isActualizandoEmpresas: false,
                textoBtnActualizarEmpresas: "Actualizar Empresas",
                filterData: "",
                classActualizar: "",
                /**ELIMINAR */



                
                



                



                trabajadoresFiltro: [],
                trabajadoresFiltrados: [],

                trabajadoresSelectedModal: [],

                mostrarMensajes: false,
                columnasMensajesAct: [
                    { title: 'Cod Empresa', dataIndex: 'codEmpresa', width: '15%', ellipsis: true, align: 'center'},
                    { title: 'Mensajes', scopedSlots: { customRender: 'mensajes' }, width: '70%', align: 'left'},
                ],
                mensajesActualizacion: [],

                
                subirDocumentosModal: false,
                empresaSeleccionadaSubirArchivos: null,

                documentoSelectedModal: null,
                visorPdf: false,
                rqstTrabsModal: null,
                empresaSelModal: null,
                trabajadorSelectedModal: {},
                trabajadoresModal: [],
                isLoadingTrabajadoresModal: false,
                currentPageTrabModal: 1,
                totalPagesTrabModal: 20,

                mostrarSubirDocumentos: false,

                columnasArchivosContratos: [
                    { title: 'Tipo Dcto.', scopedSlots: { customRender: 'tipoDoc' }, width: '10%', align: 'left' ,ellipsis: false},
                    { title: 'Fecha Emisión', scopedSlots:{ customRender: 'fechaEmision' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Fecha Início Contrato', scopedSlots: { customRender: 'fechaContrato' }, width: '15%', align: 'left', ellipsis: false},
                    { title: 'Fecha Vencimiento', scopedSlots: { customRender: 'fechaVencimiento' }, width: '15%', align: 'center', ellipsis: false},
                    { title: 'Fecha Término', scopedSlots: { customRender: 'fechaTermino' }, width: '15%', align: 'center', ellipsis: false},
                    { title: 'Documentos', scopedSlots: { customRender: 'documentos' }, width: '20%', align: 'center', ellipsis: false},
                ],
                columnasArchivosLiquidaciones: [
                    { title: 'Nombre', scopedSlots: { customRender: 'nombreArchivo' }, width: '20%', align: 'left' ,ellipsis: false},
                    { title: 'Fecha Emisión', scopedSlots:{ customRender: 'fechaEmision' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Documentos', scopedSlots: { customRender: 'documentos' }, width: '20%', align: 'center', ellipsis: false},
                ],

                /* subir archivos*/
                columnasSubidaArchivos: [
                    { title: 'Nombre Archivo', scopedSlots: { customRender: 'nombreArchivo' }, width: '20%', align: 'left' ,ellipsis: false},
                    { title: 'Progreso', scopedSlots:{ customRender: 'progreso' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Tamaño', scopedSlots: { customRender: 'tamanio' }, width: '10%', align: 'left', ellipsis: false},
                    { title: 'Velocidad', scopedSlots: { customRender: 'velocidad' }, width: '10%', align: 'center', ellipsis: false},
                    { title: 'Estado', scopedSlots: { customRender: 'estado' }, width: '20%', align: 'center', ellipsis: false},
                    { title: 'Acciones', scopedSlots: { customRender: 'acciones' }, width: '20%', align: 'center', ellipsis: false},
                ],

                
                opcionSeleccionada: null,
                opcionesFiltrosTrabajadores:[
                    {
                        filtroNombre: 'Unidad',
                        opcionesFiltro: [
                            { name: 'Vue.js', category: 'Front-end' },
                            { name: 'Adonis', category: 'Backend' }
                        ]
                    },
                    {
                        filtroNombre: 'Sección',
                        opcionesFiltro: [
                            { name: 'Rails', category: 'Backend' },
                            { name: 'Sinatra', category: 'Backend' }
                        ]
                    },
                    {
                        filtroNombre: 'Sucursal',
                        opcionesFiltro: [
                            { name: 'Laravel', category: 'Backend' },
                            { name: 'Phoenix', category: 'Backend' }
                        ]
                    }
                ],

                files: [],
                accept: "",
                extensions: "",
                // extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
                // extensions: /\.(gif|jpe?g|png|webp)$/i,
                minSize: 1024,
                size: 0,
                multiple: true,
                directory: false,
                drop: true,
                dropDirectory: true,
                addIndex: false,
                thread: 3,
                name: "file",
                postAction: cons.port+"://" + cons.ipServer + "/upload_file",
                putAction: "",
                headers: {
                    Authorization: JSON.parse(this.$cookie.get('userLogginToken')).tokenUsuario,
                },
                data: {
                    _csrf_token: "xxxxxx",
                },
                autoCompress: 1024 * 1024,
                uploadAuto: false,
                isOption: false,
                addData: {
                    show: false,
                    name: "",
                    type: "",
                    content: "",
                },
                editFile: {
                    show: false,
                    name: "",
                },
                
            }
        },
        created: function(){},
        
        watch: { 
            "editFile.show"(newValue, oldValue) {
                if (!newValue && oldValue) {
                this.$refs.upload.update(this.editFile.id, {
                    error: this.editFile.error || "",
                });
                }
                if (newValue) {
                this.$nextTick(function () {
                    if (!this.$refs.editImage) {
                    return;
                    }
                    let cropper = new Cropper(this.$refs.editImage, {
                    autoCrop: false,
                    });
                    this.editFile = {
                    ...this.editFile,
                    cropper,
                    };
                });
                }
            },
            "addData.show"(show) {

                if (show) {
                    this.addData.name = "";
                    this.addData.type = "";
                    this.addData.content = "";
                }
            },
        },
        beforeMount() {
            let cookieUsername = this.$cookie.get('userLogginToken')
            if(cookieUsername === null || cookieUsername === ""){
                this.$router.push("/pages/login");
            }
            this.obtenerPeriodos()
            this.obtenerTiposDocumentos()
            this.obtenerSoloInfoEmpresas()

        },
        mounted(){
            this.checkDevice()
            window.addEventListener('resize', this.checkDevice);
            window.addEventListener('orientationchange', this.checkDevice);
        },
        beforeDestroy () {
            this.empresas.forEach(empresa => {
                if(empresa.subidaDocsID != null){
                    clearInterval(empresa.subidaDocsID)
                }
            });
            // Eliminar el event listener cuando el componente se destruye
            window.removeEventListener('resize', this.checkDevice());
            window.removeEventListener('orientationchange', this.checkDevice());
            
        },
        methods: {
            checkDevice() {
                this.isMobile = util.getTipoDispositivoEsMobile()
            },
            /*Opciones Menus selecciones Iniciales */            
            obtenerTiposDocumentos: function(){
                this.isLoadingTipoDocumentos = true

                var url =  this.urlBase + apiUrls.remuneraciones.getTipoDocumentos //"/get_tipo_documentos/"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {

                    this.tipoDocumentos = result.data

                    if(this.tipoDocumentos.length == 1){
                        this.tipoDocumentoSelected = this.tipoDocumentos[0]
                    }

                }, error => {
                    this.errorDctos = error.response.data
                })
                .finally(() => {
                    this.isLoadingTipoDocumentos = false
                });
            },
            obtenerPeriodos: function(){

                this.isLoadingPeriodos = true
                var url = this.urlBase + apiUrls.remuneraciones.getPeriodos

                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    this.periodos = result.data
                }, error => {
                    
                    this.mostrarNotificaciones(3, "Ups, Tuvimos un problema al obtener los periodos, si el problema persiste contacte con nuestro soporte, Gracias.")
                })
                .finally(() => {
                    this.isLoadingPeriodos = false
                });
            },       
            seleccionarPeriodoActual: function(){
                this.selectPeriodoActual = !this.selectPeriodoActual
                
                if(this.errorDctos != null){
                    this.mostrarNotificaciones(2, this.errorDctos)
                } else{
                    let anioActual = new Date().getFullYear()
                    let mesActual = new Date().getMonth()//se obtiene el anterior
                    if(mesActual == 0){
                        mesActual = 12
                        anioActual = anioActual - 1
                    }
                    if(this.selectPeriodoActual){
                        this.tipoDocumentoSelected = this.tipoDocumentos[0]
                        if(this.tipoDocumentoSelected.valor == "liquidaciones"){
                            for(let periodo in this.periodos){
                                if(this.periodos[periodo].valor == anioActual){
                                    this.periodoSelected = this.periodos[periodo]
                                    let mesEncontrado = false

                                    for(let mes in this.periodoSelected.meses){
                                        if(this.periodoSelected.meses[mes].valor == mesActual){
                                            this.mesSelected = this.periodoSelected.meses[mes]
                                            mesEncontrado = true
                                            break
                                        }
                                    }
                                    if(mesEncontrado)
                                        break
                                }
                            }
                        }
                        this.obtenerEmpresas(this.tipoDocumentoSelected)
                    }
                }
            },
            seleccionarTipoDocumento: function(tipoDoc){
                if(tipoDoc.valor =='contratos')
                    this.obtenerEmpresas(tipoDoc)
                else{
                    if(this.periodoSelected != null) 
                        this.seleccionarAnioPeriodo(this.periodoSelected)
                    else{
                        this.empresas = []
                        this.empresasFiltradas = []
                    }
                }
            },
            seleccionarAnioPeriodo: function(periodoAnioSel){
                this.periodoSelected = periodoAnioSel
                this.empresas = []
                this.empresasFiltradas = []

                if(this.mesSelected != null){
                    let encontrado = false
                    this.periodoSelected.meses.forEach(mes =>{
                        if(mes.valor == this.mesSelected.valor){
                            encontrado = true
                            this.seleccionarMeses(mes)
                        }
                    })
                    if(!encontrado){
                        this.mostrarNotificaciones(2, `El año seleccionado no tiene el mes ${this.mesSelected.nombreMes}, se seleccionará el último disponible`)
                        this.seleccionarMeses(this.periodoSelected.meses[this.periodoSelected.meses.length - 1])
                    }
                }
            },
            seleccionarMeses: function(mes){
                this.mesSelected = mes
                if(this.tipoDocumentoSelected != null){
                    this.obtenerEmpresas(this.tipoDocumentoSelected)
                }
            },
            /*Fin Opciones Menus selecciones Iniciales */
            getDocsProcesados: function(archivosSubir){
                let archivosExitosos = 0
                let totalArchivos = archivosSubir.length
                let archivosProcesando = 0
                if(totalArchivos>0){
                    archivosSubir.forEach(file => {
                        if(file.active){
                            archivosProcesando++
                        }
                        if (file.success) {
                            archivosExitosos++
                        }
                    });
                }
                return archivosExitosos

            },
            getPorcentajeSubidos: function(archivosSubir){
                console.log("archivosSubir", archivosSubir)

                let archivosExitosos = 0
                let totalArchivos = archivosSubir.length
                let archivosProcesando = 0
                if(totalArchivos>0){
                    archivosSubir.forEach(file => {
                        if(file.active){
                            archivosProcesando++
                        }
                        if (file.success) {
                            archivosExitosos++
                        }
                    });

                    return ((archivosExitosos / totalArchivos) * 100)
                } else{
                    return 0
                }
            },
            getColorPorcentaje: function(archivosSubir){
                let algunError = false;
                let todosCompletados = true;

                archivosSubir.forEach(file => {
                    if (file.error) {
                        algunError = true;
                    }
                    if (!file.success) {
                        todosCompletados = false;
                    }
                });

                if (algunError) {
                    return "danger";
                }
                if (todosCompletados) {
                    return "success";
                }
                
                return "info";
                
            },
            /* proceso Revision */
            obtenerEmpresas: function(tipoDoc){
                this.tipoDocumentoSelected = tipoDoc
                if(this.tipoDocumentoSelected != null){
                    if(this.rqstEmps != null){
                        this.rqstEmps.cancel()
                        this.rqstEmps.msg = "cancelado";
                        this.rqstEmps = null;
                        
                        this.isLoadingEmpresas = false
                    }

                    const axiosSource = axios.CancelToken.source();
                    this.rqstEmps = { cancel: axiosSource.cancel, msg: "Cargando..." };
                    
                    this.empresas = []
                    this.empresasFiltradas = []
                    this.isLoadingEmpresas = true
                    let url =  this.urlBase + apiUrls.general.getEmpresas
                    
                    axios({
                        method: "POST", 
                        "url": url,
                        cancelToken: axiosSource.token,
                        "data": {
                            "tipoBD": "REMU",
                            "filtrarPorPeriodo": this.mostrarPeriodo,
                            "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                            "mesPeriodo":(this.mesSelected != null) ? this.mesSelected.valor : null,
                            "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : null,
                            "empSelecteds": this.emFlt,
                            "paginacionEmpresas": {
                                "actual": this.paginacionEmpresas.current,
                                "tamanioPagina": this.paginacionEmpresas.pageSize
                            }
                        },
                        "headers": {
                            'Authorization': `${this.tokenLogin}`
                        }
                    }).then(result => {
                        this.empresas = result.data.empresas
                        this.empresasFiltradas = result.data.empresas

                        for(var colum in this.columns){
                            
                            if(this.columns[colum].key === "rutEmpresa"){
                                this.columns[colum].filters = result.data.filtros.rutEmpresa
                            } else if(this.columns[colum].key === "nombreEmpresa"){
                                this.columns[colum].filters = result.data.filtros.nombreEmpresa
                            }
                        }

                        if(this.empresas.length<=0)
                            this.locale.emptyText = "No hay empresas para mostrar"

                        const pagination = { ...this.paginacionEmpresas };
                        pagination.total = result.data.totalEmpresas
                        this.paginacionEmpresas = pagination

                        this.isRefrescando = false
                        this.isLoadingEmpresas = false
                        this.selectPeriodoActual = false
                        this.rqstEmp = null
                        if(result.data.mensajeGeneral != null){
                            this.mostrarNotificaciones(2, result.data.mensajeGeneral)
                        }
                    }, 
                    /*cancel => {
                        
                    },*/
                    error => {
                        this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                        this.isRefrescando = false
                        this.isLoadingEmpresas = false
                        this.selectPeriodoActual = false
                        this.rqstEmp = null
                    })
                    .finally(() => {
                    });
                }
            },
            seleccionarMostraPeriodo: function(){
                this.mostrarPeriodo = !this.mostrarPeriodo
                if(this.mostrarPeriodo){
                    if(this.tipoDocumentoSelected != null && this.mesSelected != null && this.periodoSelected != null){
                        this.obtenerEmpresas(this.tipoDocumentoSelected)
                    } else{
                        this.empresas = []
                        this.empresasFiltradas = []
                    }
                }
                else{
                    if(this.tipoDocumentoSelected != null){
                        this.obtenerEmpresas(this.tipoDocumentoSelected)
                    }
                    else{
                        this.empresas = []
                        this.empresasFiltradas = []
                    }
                }
            },
            cancelarEnvio: function(){
                this.mailEmpresaOk = true
                this.mensajeCorreoInvalido = false
                this.mailValido = true
                //this.mailEmpresaRespaldo = ""
                this.modificarCorreoEmpresa = false
                this.empresaEnviada = false

                this.empresaEnviar.userNotificar.forEach(user => {
                    user.mailUser = user.mailRespaldo
                })
                this.respEmpresaEnviar = {}
            },
            enviarEmpresa: function(empresa){

                this.respEmpresaEnviar = empresa

                this.mailEmpresaOk = true
                this.mensajeCorreoInvalido = false
                this.mailValido = true
                //this.mailEmpresaRespaldo = ""
                this.modificarCorreoEmpresa = false
                this.empresaEnviada = false

                if(empresa.userNotificar.length == 0){

                    this.mailEmpresaOk = false
                    this.mailValido = false
                    this.modificarCorreoEmpresa = true

                } else{

                    empresa.userNotificar.forEach(user => {

                        if(util.validarMail(user.mailUser)){
                            user.mailValidado = true
                        } else {
                            this.mailEmpresaOk = false
                            this.mailValido = false
                            user.mailValidado = false
                            this.modificarCorreoEmpresa = true
                        }

                    })
                }

                this.empresaEnviar = empresa
                //this.mailEmpresaRespaldo = empresa.userNotificar.mailUser
                this.mostrarModalEnvioEmpresa = true
            },
            cancelarRevision: function(empresa){
                empresa.cancelandoEnvio = true
                let url = `${this.urlBase + apiUrls.revisionDocumentos.cancelarRevision}`
                axios({
                    url: url,
                    method: "POST",
                    "data": {
                        "datosRevision": {
                            "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : null,
                            "mesPeriodo": (this.mesSelected != null) ? this.mesSelected.valor : null,
                            "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                            "idEmpresa": empresa.idEmpresa,
                            "mostrarPorPeriodo": this.mostrarPeriodo,
                            "userNotificar": empresa.userNotificar,
                        }
                    },
                    headers: {
                        Authorization: `${this.tokenLogin}`,
                    },
                })
                .then((result) => {

                        this.mostrarNotificaciones(1, result.data.mensaje)
                        empresa.estado = result.data.estadoEmpresa
                    },
                    (error) => {
                        this.mostrarNotificaciones(3, error.response.data.mensaje)
                    }
                )
                .finally(() => {
                    empresa.cancelandoEnvio = false
                    
                });
            },
            
            aceptarEmpresa: function (empresa) {
                empresa.enviandoEmpresa = true
                let url = `${this.urlBase}/aceptar_documentos/${empresa.idEmpresa}/${(this.mesSelected != null) ? this.mesSelected.valor : 'null'}/${(this.periodoSelected != null) ? this.periodoSelected.valor : 'null'}/${(this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : 'null'}`
                //var url = this.urlBase + "/aceptar_documentos/" + empresa.idEmpresa + "/" + this.mesSelected.valor + "/" + this.periodoSelected.valor + "/" + this.tipoDocumentoSelected.valor;
                axios({
                    url: url,
                    method: "POST",
                    "data": {
                        "info": {
                            "notificarMail": empresa.notificar.mail,
                            "notificarPlataorma": empresa.notificar.plataforma,
                        }
                    },
                    headers: {
                        Authorization: `${this.tokenLogin}`,
                    },
                })
                    .then(
                    (result) => {
                        //this.selempresa.estado = result.data.estadoEmpresa;
                        
                        this.mostrarNotificaciones(1, result.data.mensaje)
                        //this.$notification.success(result.data.mensaje, { timer: 10, position: "bottomRight" });
                        empresa.estado = result.data.estadoEmpresa;
                        empresa.fechaRespuesta = result.data.fechaModif

                        empresa.enviandoEmpresa = false
                    },
                    (error) => {
                        this.mostrarNotificaciones(3, error.response.data.mensaje)
                        //this.$notification.error(error.response.data.mensaje, {timer: 10, position: "bottomRight"});
                        empresa.enviandoEmpresa = false
                    }
                    )
                    .finally(() => {
                    });
                },
            cerrarPeriodo: function(empresa){
                let url = `${this.urlBase}/abrir_cerrar_periodo_empresa/${empresa.idEmpresa}/${(this.mesSelected != null) ? this.mesSelected.valor : 'null'}/${(this.periodoSelected != null) ? this.periodoSelected.valor : 'null'}/2`//2 - cerrar

                //var url = this.urlBase + "/abrir_cerrar_periodo_empresa/" + empresa.idEmpresa + "/" + this.mesSelected.valor + "/" + this.periodoSelected.valor + "/" + 2;//2 - cerrar

                axios({
                    method: "POST",
                    url: url,
                    headers: {
                        Authorization: `${this.tokenLogin}`,
                    },
                    }).then((result) => {
                        
                        this.$notification.success(`Se ha Cerrado el periodo ${this.mesSelected.nombreMes}-${this.periodoSelected.valor}, ${empresa.nombreEmpresa}`, {  timer: 10, position:"bottomRight", showCloseIcn:true});
                        empresa.periodoCerrado = true;
                        empresa.fechaPeriodoCerrado = result.data.fechaModificacion;
                    },
                    (error) => {
                        this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight", showCloseIcn:true});
                    })
                    .finally(() => {
                    });
            },
            abrirPeriodo: function(empresa){

                var url = cons.port+"://" + cons.ipServer + "/abrir_cerrar_periodo_empresa/" + empresa.idEmpresa + "/" + this.mesSelected.valor + "/" + this.periodoSelected.valor + "/" + 1;//1 - abrir

                axios({
                    method: "POST",
                    url: url,
                    headers: {
                        Authorization: `${this.tokenLogin}`,
                    },
                    }).then((result) => {
                        this.$notification.success(`Se ha Abierto el periodo ${this.mesSelected.nombreMes}-${this.periodoSelected.valor}, ${empresa.nombreEmpresa}`, {  timer: 10, position:"bottomRight", showCloseIcn:true});
                        empresa.periodoCerrado = false;
                    },
                    (error) => {
                        
                        this.$notification.error(error.response.data, {  timer: 10, position:"bottomRight", showCloseIcn:true});
                    })
                    .finally(() => {
                    });
            },
            
            seleccionFiltro: function(evento, empresa){
                empresa.opcionSeleccionadaFiltro = evento
                this.obtenerTrabajadores(true, empresa)
            },
            //TrabajadoresFiltro
            getTrabajadores: function(expand, empresa){
                if(expand){
                    empresa.currentPageFiltros = 1
                    empresa.trabajadoresFiltro = []
                }
                
                if(empresa.rqstTrabs != null){
                    empresa.rqstTrabs.cancel()
                    empresa.rqstTrabs.msg = "cancelado";
                    empresa.rqstTrabs = null;
                    
                    empresa.isLoadingTrabajadoresFiltros = true;
                }
                const axiosSource = axios.CancelToken.source();
                empresa.rqstTrabs = { cancel: axiosSource.cancel, msg: "Cargando..." };

                empresa.isLoadingTrabajadoresFiltros = true;
                
                let url = `${this.urlBase + apiUrls.general.getTrabajadoresEmpresa + empresa.idEmpresa}/${(this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : 'null'}/${(this.mesSelected != null) ? this.mesSelected.valor : 'null'}/${(this.periodoSelected != null) ? this.periodoSelected.valor : 'null'}`


                axios({
                method: "POST",
                url: url,
                cancelToken: axiosSource.token,
                "data": {
                    "filtrarPorPeriodo": this.mostrarPeriodo,
                    "paginacionTrabajadores": {
                        "strFiltro": empresa.strFiltroTrabajadores,
                        "actual": empresa.currentPageFiltros,
                        "tamanioPagina": 10,
                        "filtros": empresa.opcionSeleccionadaFiltro
                    }
                },
                headers: {
                    Authorization: `${this.tokenLogin}`,
                },
                }).then((result) => {
                    empresa.totalPages = Number((result.data.totalTrabajadores/10).toFixed(0))
                    if(empresa.trabajadoresFiltro.length == 1 && empresa.trabajadoresFiltro[0].rutTrabajador == '0'){
                        empresa.trabajadoresFiltro = []
                    }

                    empresa.trabajadoresFiltro = empresa.trabajadoresFiltro.concat(result.data.trabajadores)
                    empresa.currentPageFiltros++
                    
                    empresa.isLoadingTrabajadoresFiltros = false;
                    empresa.rqstTrabs = null;
                    if(result.data.mensajeGeneral != null){
                        this.mostrarNotificaciones(2, result.data.mensajeGeneral)
                        //this.$notification.warning(result.data.mensajeGeneral, {  timer: 10, position:"bottomRight", showCloseIcn:true});
                    }
                },
                cancel =>{
                    empresa.isLoadingTrabajadoresFiltros = false;

                },
                (error) => {
                    
                    empresa.isLoadingTrabajadoresFiltros = false;
                    empresa.rqstTrabs = null;

                }
                )
                .finally(() => {
                    empresa.isLoadingTrabajadoresFiltros = false;
                });
            },
            enviaraTodos: function(empresa){
                var msjOriginal = empresa.txtEnviarTodos
                empresa.isLoadingEnvioTodos = true
                empresa.txtEnviarTodos = "enviando"
                var url = cons.port+"://" + cons.ipServer + "/" + "enviar_documento_trabajador/"

                var trabajadores = []
                if(empresa.trabajadoresFiltrados != null && empresa.trabajadoresFiltrados.length > 0){
                    empresa.trabajadoresFiltrados.forEach(trabajador => {
                        trabajadores.push({"rutTrabajador": trabajador.rutTrabajador})
                    });
                }

                axios({
                    method: "POST",
                    url: url,
                    "data": {
                        "trabajadoresEnviar": {
                        "enviarTodos": true,
                        "idEmpresa": empresa.idEmpresa,
                        "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : 'null',
                        "mesPeriodo": (this.mesSelected != null) ? this.mesSelected.valor : 'null',
                        "tipoDoc": this.tipoDocumentoSelected.valor,
                        "filtrarPorPeriodo": this.filtrarPorPeriodo,
                        "trabajadores":trabajadores
                        }
                    },
                    headers: {
                        Authorization: `${this.tokenLogin}`,
                    },
                    })
                    .then((result) => {
                        this.$notification.success(
                        result.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                        );
                        //this.obtenerTrabajadores(true, empresa)
                    },
                    (error) => {
                        this.$notification.error(
                        error.response.data.mensaje,
                        { timer: 10, position: "bottomRight" }
                        );
                    }
                    )
                    .finally(() => {
                        empresa.txtEnviarTodos = msjOriginal
                        empresa.isLoadingEnvioTodos = false
                    });

            },
            rmvEmpSel: function(emp){
                for(var indx=0;indx<=this.emFlt.length;indx++){
                    if(this.emFlt[indx].idEmpresa == emp.idEmpresa){
                        this.emFlt.splice(indx, 1)
                        break;
                    }
                }

                if(this.mesSelected != null && this.periodoSelected != null)
                    this.obtenerEmpresas(this.tipoDocumentoSelected)
                
            },
            filtrarEmpr: function(emp){
                this.emFlt.push(emp)
                if(this.mostrarPeriodo){
                    if(this.mesSelected != null && this.periodoSelected != null)
                        this.obtenerEmpresas(this.tipoDocumentoSelected)
                } else{
                    this.obtenerEmpresas(this.tipoDocumentoSelected)
                }
                
            },
            customLabelEmp (empresa) {
                return `(${empresa.codEmpresa}) ${empresa.nombreEmpresa}`;
            },
            customTrabajador: function(trabajador) {
                if(trabajador.rutTrabajador == '0'){
                    return 'Buscando...'
                }else {
                    return `${trabajador.rutTrabajador} – ${trabajador.nombreTrabajador} ${(trabajador.documentos.length >0)?"":"(Sin Documento)"}`
                }
            }, 
            descargarArchivo: function(tipoDescarga, empresa){
                empresa.isDescargando = true
                
                let url = this.urlBase + `/descargar_documentos/`
                let trabajadores = []
                if(empresa.trabajadoresFiltrados != null && empresa.trabajadoresFiltrados.length > 0){
                    empresa.trabajadoresFiltrados.forEach(trabajador => {
                        trabajadores.push({"rutTrabajador": trabajador.rutTrabajador})
                    });
                }
                axios({
                    url: url,
                    method: 'POST',
                    "data": {
                        "tipoBd": "REMU",
                        "tipoDescarga": tipoDescarga,
                        "codEmpresa": empresa.codEmpresa,
                        "idEmpresa": empresa.idEmpresa,
                        "filtrarPorPeriodo": this.mostrarPeriodo,
                        "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                        "mesPeriodo":(this.mesSelected != null) ? this.mesSelected.valor : null,
                        "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : null,
                        "infoTrabajadores": {
                            "trabajadoresSelected": [],
                            "trabajadores": trabajadores
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    },

                }).then((response) => {
                    //this.msjDescargando = "Iniciando Descarga"

                    //this.$notification.success(response.mensajeGeneral, {  timer: 10, position:"bottomRight", showCloseIcn:true});
                    //this.mostrarNotificaciones(1, response.data.mensajeGeneral)
                    const link = document.createElement('a')
                    link.href = response.data.archivo.link
                    link.download = response.data.archivo.nombre
                    link.click()
                    URL.revokeObjectURL(link.href)

                    //this.$notification.success("Iniciando Descarga", {  timer: 10, position:"bottomRight", showCloseIcn:true});
                    this.mostrarNotificaciones(1, `${response.data.mensajeGeneral}, Iniciando Descarga`)

                }, error => {
                    this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                    //this.$notification.error(error.response.data.mensajeGeneral, {  timer: 10, position:"bottomRight", showCloseIcn:true});
                })
                .finally(() => {
                    empresa.isDescargando = false
                });
                
            },
            
            isEmailValido: function() {
                var hayInvalido = false

                this.empresaEnviar.userNotificar.forEach(user => {
                    
                    if(util.validarMail(user.mailUser)){
                        user.mailValidado = true
                        user.editCorreoEncargado = false
                    } else {
                        this.mailEmpresaOk = false
                        this.mailValido = false
                        user.mailValidado = false
                        this.modificarCorreoEmpresa = true
                        hayInvalido = true
                    }

                })

                if(!hayInvalido){
                    this.mailEmpresaOk = true
                    this.mailValido = true
                }
            },
            editarCorreoEmpresa: function(user){
                user.editCorreoEncargado = !user.editCorreoEncargado
                this.modificarCorreoEmpresa = true
                user.mailRespaldo = user.mailUser

            },
            cancelarEditarEmpresa: function(user){
                if(user.mailUser != user.mailRespaldo)
                    user.mailUser = user.mailRespaldo
                
                user.editCorreoEncargado = false
            },
            actualizarDocumentosEmpresa: function(empresa){
                empresa.isActualizandoDocumentos = true
                empresa.txtActualizarDocs = "Revisando Documentos"

                var trabajadores = []
                if(empresa.trabajadoresFiltrados != null && empresa.trabajadoresFiltrados.length > 0){
                    empresa.trabajadoresFiltrados.forEach(trabajador => {
                        trabajadores.push({"rutTrabajador": trabajador.rutTrabajador})
                    });
                }
                
                let url =  this.urlBase + apiUrls.remuneraciones.actualizarDocumentos

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "infoActualizar": {
                            "idEmpresa": empresa.idEmpresa,
                            "tipoBD": "REMU",
                            "tipoDocumentos": this.tipoDocumentoSelected.valor,
                            "mes": this.mesSelected.valor,
                            "anio": this.periodoSelected.valor,
                            "trabajadores": trabajadores
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    empresa.mensajeActualizacionDocs = result.data
                    empresa.mostrarMensajeActualizacionDocumentos = true
                    this.obtenerTrabajadores(true, empresa)
                    this.$notification.success(result.data, {  timer: 10, position:"bottomRight"});
                }, error => {
                    if("tipoMensaje" in error.response.data){
                        if(error.response.data.tipoMensaje == "alerta"){
                            this.mostrarNotificaciones(2, error.response.data.mensaje)
                        } else {
                            this.mostrarNotificaciones(3, error.response.data.mensaje)
                        }
                    } else{
                        this.mostrarNotificaciones(3, error.response.data)
                    }
                })
                .finally(() => {
                    empresa.isActualizandoDocumentos = false
                    if(empresa.trabajadoresFiltrados.length > 0 ){
                        empresa.txtActualizarDocs = "Actualizar Documentos Trabajadores Seleccionados"
                    } else{
                        empresa.txtActualizarDocs = "Actualizar Documentos"
                    }
                    
                });

            },
            
            obtenerSoloInfoEmpresas: function(){
                this.isLoadingEmpresasInfo = true
                
                var url = this.urlBase + apiUrls.general.getEmpresasInfo+"/REMU"
                
                axios({
                    method: "GET", 
                    "url": url,
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    
                    this.empresasInfo = result.data;

                }, error => {
                    let mensaje = `Problema al obtener empresas , ${error}`
                    this.textoSinEmpresas = mensaje
                })
                .finally(() => {
                    this.isLoadingEmpresasInfo = false
                });
            },
            cambioPaginaEmpresas: function(pagination, filters, sorter, filasActuales){
                this.paginacionEmpresas = pagination
                this.obtenerEmpresas(this.tipoDocumentoSelected)
            },
            expandirEmpresa: function(expanded, record){
                record.expandirEmpresa = expanded
                if(expanded){
                    //if(!record.periodoCerrado)
                    //    this.actualizarDocumentosEmpresa(record)

                    this.obtenerTrabajadores(expanded, record)
                    //this.getTrabajadores(true, record)
                    
                }
            },
            filtrarTrabajadores: function(evento, empresa){
                empresa.trabajadoresFiltrados = evento
                empresa.paginacion.current = 1;

                this.obtenerTrabajadores(true, empresa)
            },
            obtenerTrabajadores: function(expanded, empresa){
                if(expanded){
                    
                    if(empresa.trabajadoresFiltrados.length > 0 ){
                        empresa.txtEnviarTodos = "Enviar Documentos a Trabajadores Seleccionados"
                        empresa.txtActualizarDocs = "Actualizar Documentos Trabajadores Seleccionados"
                        empresa.txtDescargarDocumentos = "Descargar Documentos Trabajadores Seleccionados"
                    } else{
                        empresa.txtEnviarTodos = empresa.txtEnviarTodosResp
                        empresa.txtActualizarDocs = "Actualizar Documentos"
                        empresa.txtDescargarDocumentos = "Descargar Documentos"
                    }

                    empresa.isLoadingTrabajadores = true
                    let url = `${this.urlBase + apiUrls.general.getTrabajadoresEmpresa + empresa.idEmpresa}/${(this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : 'null'}/${(this.mesSelected != null) ? this.mesSelected.valor : 'null'}/${(this.periodoSelected != null) ? this.periodoSelected.valor : 'null'}`
                    
                    axios({
                        method: "POST", 
                        "url": url,
                        "data": {
                            "filtrarPorPeriodo": this.mostrarPeriodo,
                            "paginacionTrabajadores": {
                                "actual": empresa.paginacion.current,
                                "tamanioPagina": empresa.paginacion.pageSize,
                                "filtrosTrabajadores": empresa.trabajadoresFiltrados,
                                "filtros": empresa.opcionSeleccionadaFiltro
                            }
                        },
                        "headers": {
                            'Authorization': `${this.tokenLogin}`
                        }
                    }).then(result => {

                        empresa.trabajadores = result.data.trabajadores
                        empresa.totalTrabajadoresDocumentos = result.data.totalDocumentosTrab
                        empresa.totalTrabajadores = result.data.totalTrabajadores

                        if(empresa.totalTrabajadoresDocumentos >0){
                            empresa.hayDocumentosTrabajador = true
                        }
                        /*
                        empresa.totalTrabajadores = empresa.trabajadores.length
                        empresa.totalTrabajadoresDocumentos = 0
                        empresa.trabajadores.forEach(trab =>{
                            if(trab.documento.hayDocumento){
                                empresa.hayDocumentosTrabajador = true
                                empresa.totalTrabajadoresDocumentos++
                            }
                        })*/
                        const pagination = { ...empresa.paginacion };
                        pagination.total = result.data.totalTrabajadores
                        pagination.empresa = empresa
                        empresa.paginacion = pagination

                        this.$refs.filtroTrab.getTrabajadores()
                        //empresa.totalTrabajadores
                        /*const pagination = { ...empresa.paginacion };
                        pagination.total = result.data.totalTrabajadores
                        pagination.empresa = empresa
                        empresa.paginacion = pagination*/

                    }, error => {
                        
                        this.mostrarNotificaciones(3, error.response.data.mensajeGeneral)
                    })
                    .finally(() => {
                        empresa.isLoadingTrabajadores = false
                    });
                }
            },
            enviarRevision: function(){

                this.isLoadingEnviandoEmpresa = true
                this.empresaEnviar.enviandoEmpresa = true
                
                
                
                
                this.mostrarModalEnvioEmpresa = false
                this.mostrarNotificaciones(1, `Enviando empresa ${this.empresaEnviar.codEmpresa}`)
                
                
                let url = this.urlBase + apiUrls.remuneraciones.enviarEmpresaRevision

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {
                        "datosEnvio": {
                            "modificarCorreoEmpresa": this.modificarCorreoEmpresa,
                            "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : null,
                            "mesPeriodo": (this.mesSelected != null) ? this.mesSelected.valor : null,
                            "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                            "empresaEnviar": {
                                "idEmpresa": this.empresaEnviar.idEmpresa,
                                "userNotificar": this.empresaEnviar.userNotificar,
                                "notificar": this.empresaEnviar.notificar
                            },
                            "mostrarPorPeriodo": this.mostrarPeriodo,
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {

                    this.empresas[this.empresas.indexOf(this.empresaEnviar)].estado = result.data.estadoEmpresa
                    if(this.obtenerTrabajadores(this.empresas[this.empresas.indexOf(this.empresaEnviar)].hasOwnProperty('expandirEmpresa'))){
                        this.empresas[this.empresas.indexOf(this.empresaEnviar)].expandirEmpresa = false
                    }
                    
                    this.obtenerTrabajadores(this.empresas[this.empresas.indexOf(this.empresaEnviar)].expandirEmpresa, this.empresas[this.empresas.indexOf(this.empresaEnviar)])
                    
                    this.mostrarNotificaciones(1, result.data.mensaje)
                    
                    
                    
                }, error => {
                    this.mostrarNotificaciones(3, error.response.data.mensaje)
                    
                    
                })
                .finally(() => {
                    this.isLoadingEnviandoEmpresa = false
                    this.empresaEnviar.enviandoEmpresa = false
                });
            },
            mostrarInfoEmpresa: function(empresa){
                this.empresaSeleccionadaInfo = empresa.infoEmpresa
                this.infoEmpresaModal = true
            },
            mostrarSubirArchivos: function(empresa){
                empresa.mostrarSubirDocumentos = !empresa.mostrarSubirDocumentos

                this.empresaSeleccionadaSubirArchivos = empresa
                this.subirDocumentosModal = true
                
                if(empresa.mostrarSubirDocumentos){
                    this.generarLinkSubidaArchivos(empresa)
                }
            },
            generarDocumentos: function(empresa){
                
                empresa.isLoadingGenerarDocumento = true
                let url = this.urlBase + apiUrls.remuneraciones.generarDocumentosTrabajadores

                axios({
                    method: "POST", 
                    "url": url,
                    "data": {                        
                        "info": {
                            "idEmpresa": empresa.idEmpresa,
                            "mesPeriodo": (this.mesSelected != null) ? this.mesSelected.valor : null,
                            "anioPeriodo": (this.periodoSelected != null) ? this.periodoSelected.valor : null,
                            "tipoDocumento": (this.tipoDocumentoSelected != null) ? this.tipoDocumentoSelected.valor : null,
                            "trabajadores": [],
                            "filtrarPorPeriodo": this.mostrarPeriodo
                        }
                    },
                    "headers": {
                        'Authorization': `${this.tokenLogin}`
                    }
                }).then(result => {
                    //this.getTrabajadores(empresa)
                    this.obtenerTrabajadores(true, empresa)
                    this.mostrarNotificaciones(1, result.data.mensaje)
                    
                    empresa.isLoadingGenerarDocumento = false

                }, error => {
                    empresa.isLoadingGenerarDocumento = false
                    
                    this.mostrarNotificaciones(3, error.response.data.mensaje)
                })
                .finally(() => {
                    
                });

            },
            refrescarEmpresas: function(){
                this.isRefrescando = true
                this.obtenerEmpresas(this.tipoDocumentoSelected)
            },

            /* METODOS SUBIR ARCHIVOS */
            cerrarModalSubirArchivos: function () {
                this.subirDocumentosModal = false;
                
            },
            generarLinkSubidaArchivos: function(empresa){
                empresa.linkSubir = `${this.urlBase}/subir_archivos_empresa/${this.idCliente}/${empresa.idEmpresa}/${this.tipoDocumentoSelected.valor}/${this.mesSelected.valor}/${this.periodoSelected.valor}/REMU/${(this.tipoDocumentoSelected.valor =='contratos') ? this.mostrarPeriodo : true}`
                /*empresa.headers = {
                    Authorization: `${this.tokenLogin}`,
                    "data": {
                        "info": {
                            "idEmpresa": empresa.idEmpresa,
                            "tipoDocumento": this.tipoDocumentoSelected.valor,
                            "mes": this.mesSelected.valor,
                            "anio": this.periodoSelected.valor
                        }
                    }
                }*/
            },

            subirTodoActivar: function(empresa){
                if(empresa.subidaDocsID == null){
                    
                this.$refs.upload.active = true
                this.validarDocsError(empresa)

                    var idInt = setInterval(function(){
                        this.validarDocumentosSubidos(empresa);
                    }.bind(this), 1000);
                    empresa.subidaDocsID = idInt
                }

            },

            validarDocsError: function(empresa){
                empresa.archivosSubir.forEach(archivo => {
                    if(!archivo.success && archivo.error != ""){
                        this.$refs.upload.update(archivo, {active: true,error: '',progress: '0.00'})
                    }
                });

            },
            validarDocumentosSubidos: function(empresa){
                if(empresa.archivosSubir.length == 0){
                    clearInterval(empresa.subidaDocsID)
                    empresa.subidaDocsID = null
                }
                else{
                    var finalizados = 0
                    empresa.archivosSubir.forEach(archivo => {
                        if((!archivo.active && parseInt(archivo.progress) == 100) || (!archivo.active && archivo.error == "Cancelado") || (!archivo.active && archivo.success) || (!archivo.active && !archivo.success)){
                            finalizados++
                        }
                    })
                    if(finalizados == empresa.archivosSubir.length){
                        clearInterval(empresa.subidaDocsID)
                        empresa.subidaDocsID = null
                        this.obtenerTrabajadores(true, empresa)
                    }
                }
            },
            /*inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    // Before adding a file
                    // 添加文件前
                    // Filter system files or hide files
                    // 过滤系统文件 和隐藏文件
                    if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
                    return prevent();
                    }
                    // Filter php html js file
                    // 过滤 php html js 文件
                    if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
                    return prevent();
                    }
                    // Automatic compression
                    // 自动压缩
                    if (
                    newFile.file &&
                    newFile.type.substr(0, 6) === "image/" &&
                    this.autoCompress > 0 &&
                    this.autoCompress < newFile.size
                    ) {
                    newFile.error = "compressing";
                    const imageCompressor = new ImageCompressor(null, {
                        convertSize: Infinity,
                        maxWidth: 512,
                        maxHeight: 512,
                    });
                    imageCompressor
                        .compress(newFile.file)
                        .then((file) => {
                        this.$refs.upload.update(newFile, {
                            error: "",
                            file,
                            size: file.size,
                            type: file.type,
                        });
                        })
                        .catch((err) => {
                        this.$refs.upload.update(newFile, {
                            error: err.message || "compress",
                        });
                        });
                    }
                }
                if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                    // Create a blob field
                    // 创建 blob 字段
                    newFile.blob = "";
                    let URL = window.URL || window.webkitURL;
                    if (URL && URL.createObjectURL) {
                    newFile.blob = URL.createObjectURL(newFile.file);
                    }
                    // Thumbnails
                    // 缩略图
                    newFile.thumb = "";
                    if (newFile.blob && newFile.type.substr(0, 6) === "image/") {
                    newFile.thumb = newFile.blob;
                    }
                }
            },*/
            // add, update, remove File Event
            inputFile(newFile, oldFile) {
                if (newFile && oldFile) {
                    // update
                    if (newFile.active && !oldFile.active) {
                    // beforeSend
                    // min size
                    if (
                        newFile.size >= 0 &&
                        this.minSize > 0 &&
                        newFile.size < this.minSize
                    ) {
                        this.$refs.upload.update(newFile, { error: "size" });
                    }
                    }
                    if (newFile.progress !== oldFile.progress) {
                    // progress
                    
                    }
                    if (newFile.error && !oldFile.error) {
                    // error
                    }
                    if (newFile.success && !oldFile.success) {
                    // success
                        this.empresasFiltradas.forEach(empresa => {
                            if(newFile.response.idEmpresa == empresa.idEmpresa){
                                let haySinTerminar = false
                                empresa.archivosSubir.forEach(archivo =>{
                                    if(archivo.active)
                                        haySinTerminar = true
                                    
                                })
                                if(!haySinTerminar){
                                    this.obtenerTrabajadores(true, empresa)
                                }
                            }
                        })
                    
                    }
                }
                if (!newFile && oldFile) {
                    // remove
                    if (oldFile.success && oldFile.response.id) {
                    // $.ajax({
                    //   type: 'DELETE',
                    //   url: '/upload/delete?id=' + oldFile.response.id,
                    // })
                    }
                }
                // Automatically activate upload
                if (
                    Boolean(newFile) !== Boolean(oldFile) ||
                    oldFile.error !== newFile.error
                ) {
                    if (this.uploadAuto && !this.$refs.upload.active) {
                    this.$refs.upload.active = true;
                    }
                }
            },
            alert(message) {
                alert(message);
            },
            onEditFileShow(file) {
            this.editFile = { ...file, show: true };
            this.$refs.upload.update(file, { error: "edit" });
            },
            onAddData() {
                this.addData.show = false;
                if (!this.$refs.upload.features.html5) {
                    this.alert("Your browser does not support");
                    return;
                }
                let file = new window.File([this.addData.content], this.addData.name, {
                    type: this.addData.type,
                });
                this.$refs.upload.add(file);
            },


        }
        
    }
</script>

<style>
.tippy-tooltip.google-theme{
    background-color:#29235C;
    font-weight: normal;
}
.tippy-tooltip.google-theme .tippy-backdrop {
    
    background-color:#29235C;
}
.tippy-content{
    background-color: transparent;
}

/*
:style="verOpcionesDescargas?'background-color: #461A3B; border-color:#461A3B':'background-color:#29235C;border-color:#29235C'"
*/
/*.btnNormalVapp, .btnNormalVapp:hover,.btnNormalVapp:focus{
    color: white;
    background-color:#29235C;
    border-color:#29235C;
}*/

.tableInterior{
    min-height:200px;
    height:300px;
    width: 100%;
    z-index: 800;
}
@media screen and (max-width: 500px) {
    .tableInterior{
        width: 200%;
        min-height:200px;
        height:200px;
    }
}

.ant-table-expanded-row ant-table-expanded-row-level-1 .tableInterior{

    height:200px;
}


.c-switch-slider{
    border-color:#461A3B;
}
.btnNormalVapp2, .btnNormalVapp2:hover, .btnNormalVapp2:focus{
    color: white;
    background-color:#461A3B;
    border-color:#461A3B;
}
.ant-table-filter-dropdown .ant-dropdown-menu{
    max-height: 150px;
}
@keyframes rotate {from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}}
@-webkit-keyframes rotate {from {-webkit-transform: rotate(0deg);}
  to {-webkit-transform: rotate(360deg);}}
.imgr{
    -webkit-animation: 3s rotate linear infinite;
    animation: 3s rotate  linear infinite;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
}
.card-header h4{
    margin-bottom: 0;
}

.modal {
  min-width: 300px;
}
@media (min-width: 480px) {
  .modal.modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal.modal-lg,
  .modal.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal.modal-xl {
    max-width: 1140px;
  }
}
.modal-footer {
  padding: 15px 0px 0px 0px;
  border-top: 1px solid #e5e5e5;
  margin-left: -14px;
  margin-right: -14px;
}

.ant-table-body {
    min-height: 70px;
}
.ant-table-placeholder{
    border-top: 0px;
}
.multiselect__single{
    font-size: 14px;
}
    
/*.multiselect__content-wrapper{
    width: 100%; 
}*/
/*.dropdown-menu {
}*/
.dropdown-gesto .dropdown-header{
    font-size: 10pt;
    padding: 0.2rem 1.25rem;
}
.gesto .dropdown-item{
    font-size: 9pt;
    padding: 0.2rem 1.25rem;
}
/*.dropdown-header {
    font-size: 10pt;
    padding: 0.2rem 1.25rem;
}
.dropdown-item{
    font-size: 9pt;
    padding: 0.2rem 1.25rem;
}*/
.example-full .btn-group .dropdown-menu {
  display: block;
  visibility: hidden;
  transition: all 0.2s;
}
.example-full .btn-group:hover > .dropdown-menu {
  visibility: visible;
}
.example-full label.dropdown-item {
  margin-bottom: 0;
}
.example-full .btn-group .dropdown-toggle {
  margin-right: 0.6rem;
}
.example-full .filename {
  margin-bottom: 0.3rem;
}
.example-full .btn-is-option {
  margin-top: 0.25rem;
}
.example-full .example-foorer {
  padding: 0.5rem 0;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}
.example-full .edit-image img {
  max-width: 100%;
}
.example-full .edit-image-tool {
  margin-top: 0.6rem;
}
.example-full .edit-image-tool .btn-group {
  margin-right: 0.6rem;
}
.example-full .footer-status {
  padding-top: 0.4rem;
}
.example-full .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: #000;
}
.example-full .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>